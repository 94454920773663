import { Alert, MenuItem, Stack } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import { z } from "zod";
import type { Option, RushType } from "../options.ts";
import {
    type ServiceType,
    backingOptions,
    flammabilityCodeOptions,
    otherServiceOptions,
    stainProtectionOptions,
} from "../options.ts";
import type { FabricUseFormReturn } from "./FabricForm.tsx";

const defaultRushOptions: Option<RushType>[] = [
    { value: "24_rush", label: "24 Rush" },
    { value: "48_rush", label: "48 Rush" },
];

const serviceRushOptions = new Map<ServiceType, Option<RushType>[]>([
    [
        "backing",
        [
            { value: "24_rush", label: "24 Rush" },
            { value: "rush", label: "Rush" },
        ],
    ],
    ["lab_service", [{ value: "48_rush", label: "48 Rush" }]],
    ["flame_retardant", defaultRushOptions],
    ["other_service", defaultRushOptions],
    ["stain_protection", defaultRushOptions],
]);

const flammabilityCodeServices: ServiceType[] = ["flame_retardant", "lab_service"];
const rushSchema = z.string().transform((value) => (value === "no_rush" ? null : value));

export const serviceSchema = z.object({ fieldId: z.string() }).and(
    z.discriminatedUnion("type", [
        z.object({
            type: z.enum(["flame_retardant", "lab_service"]),
            flammabilityCode: z.string(),
            rush: rushSchema,
        }),
        z.object({
            type: z.literal("backing"),
            backing: z.string(),
            rush: rushSchema,
        }),
        z.object({
            type: z.literal("lamination_service"),
        }),
        z.object({
            type: z.literal("other_service"),
            otherService: z.string(),
            rush: rushSchema,
        }),
        z.object({
            type: z.literal("stain_protection"),
            stainProtection: z.string(),
            rush: rushSchema,
        }),
    ]),
);

type Props = {
    form: FabricUseFormReturn;
    fieldIndex: number;
};

const ServiceFieldSet = ({ form, fieldIndex }: Props): ReactNode => {
    const serviceType = form.watch(`services.${fieldIndex}.type`);
    const rushOptions = serviceRushOptions.get(serviceType);

    return (
        <Stack spacing={2}>
            {rushOptions && (
                <RhfTextField
                    control={form.control}
                    name={`services.${fieldIndex}.rush`}
                    label="Rush"
                    select
                >
                    <MenuItem value="no_rush">No Rush</MenuItem>
                    {rushOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
            )}

            {flammabilityCodeServices.includes(serviceType) && (
                <RhfTextField
                    control={form.control}
                    name={`services.${fieldIndex}.flammabilityCode`}
                    label="Flammability Code"
                    required
                    select
                >
                    {flammabilityCodeOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
            )}

            {serviceType === "backing" && (
                <RhfTextField
                    control={form.control}
                    name={`services.${fieldIndex}.backing`}
                    label="Backing"
                    required
                    select
                >
                    {backingOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
            )}

            {serviceType === "stain_protection" && (
                <RhfTextField
                    control={form.control}
                    name={`services.${fieldIndex}.stainProtection`}
                    label="Stain Protection"
                    required
                    select
                >
                    {stainProtectionOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
            )}

            {serviceType === "other_service" && (
                <RhfTextField
                    control={form.control}
                    name={`services.${fieldIndex}.otherService`}
                    label="Other Service"
                    required
                    select
                >
                    {otherServiceOptions.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RhfTextField>
            )}

            {serviceType === "lamination_service" && (
                <Alert severity="info">This service type has no options to select.</Alert>
            )}
        </Stack>
    );
};

export default ServiceFieldSet;
