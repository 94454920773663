import { useCreateApprovalDocumentMutation } from "@/mutations/approval-document.ts";
import { useCreateOrderSubmissionMutation } from "@/mutations/order-submission.ts";
import { errorMap } from "@/utils/zod.ts";
import { useAuth0 } from "@auth0/auth0-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Box, FormHelperText, Link, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMemo, useRef } from "react";
import type { ReactNode } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useController, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import type { CustomerInformationFormValues } from "../CustomerInformationStep/index.ts";
import type { FabricFormValues } from "../FabricStep/index.ts";
import OrderStepper, { type OrderStepperProps } from "../OrderStepper.tsx";
import Approval from "./Approval.tsx";
import BillingInformationCard from "./BillingInformationCard.tsx";
import FabricCard from "./FabricCard.tsx";
import ShippingInformationCard from "./ShippingInformationCard.tsx";

type Props = {
    stepperProps: OrderStepperProps;
    customerInformation: CustomerInformationFormValues;
    fabric: FabricFormValues;
    onStepChange: (step: number) => void;
};

const SummaryStep = ({
    stepperProps,
    customerInformation,
    fabric,
    onStepChange,
}: Props): ReactNode => {
    const { isAuthenticated } = useAuth0();
    const createApprovalDocumentMutation = useCreateApprovalDocumentMutation();
    const createOrderSubmissionMutation = useCreateOrderSubmissionMutation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    const schema = useMemo(() => {
        return z.object({
            specialCareApproved: z
                .boolean()
                .default(false)
                .refine((value) => {
                    return !fabric.specialCare || value;
                }, "Approval required"),
            biasApproved: z
                .boolean()
                .default(false)
                .refine((value) => {
                    return !fabric.plaidOrStriped || value;
                }, "Approval required"),
            tacAccepted: z
                .boolean()
                .default(false)
                .refine((value) => {
                    return isAuthenticated || value;
                }, "Acceptance required"),
            captchaToken: z
                .string()
                .nullable()
                .default(null)
                .refine((value) => isAuthenticated || value, "Required"),
        });
    }, [fabric.specialCare, fabric.plaidOrStriped, isAuthenticated]);

    type FieldValues = z.input<typeof schema>;
    type TransformedValues = z.output<typeof schema>;

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema, { errorMap }),
    });

    const { fieldState: captchaFieldState } = useController({
        control: form.control,
        name: "captchaToken",
    });

    const downloadApprovalPdf = () => {
        createApprovalDocumentMutation.mutate(
            {
                attributes: {
                    customerInformation,
                    fabric,
                },
            },
            {
                onSuccess: (presignedUrl) => {
                    window.open(presignedUrl);
                },
                onError: () => {
                    enqueueSnackbar("Failed to generate approval PDF", { variant: "error" });
                },
            },
        );
    };

    const handleSubmit = async (values: TransformedValues) => {
        let orderNumber: string | null;

        try {
            orderNumber = await createOrderSubmissionMutation.mutateAsync({
                attributes: {
                    customerInformation,
                    fabric,
                    captchaToken: values.captchaToken,
                },
            });
        } catch {
            enqueueSnackbar("Failed to submit order", { variant: "error" });
            recaptchaRef.current?.reset();
            return;
        }

        if (!orderNumber) {
            navigate("/order-confirmation");
            return;
        }

        navigate(`/order-confirmation/${orderNumber}`);
    };

    return (
        <Box sx={{ pb: 8 }} component="form" onSubmit={form.handleSubmit(handleSubmit)} noValidate>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Order Summary
            </Typography>

            <Stack spacing={2}>
                <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                    <BillingInformationCard
                        billingInformation={customerInformation.billingInformation}
                        onStepChange={onStepChange}
                        sx={{ flexGrow: 1 }}
                    />
                    <ShippingInformationCard
                        billingInformation={customerInformation.billingInformation}
                        shippingInformation={customerInformation.shippingInformation}
                        onStepChange={onStepChange}
                        sx={{ flexGrow: 1 }}
                    />
                </Stack>
                <FabricCard fabric={fabric} onStepChange={onStepChange} />
                {fabric.specialCare && (
                    <Approval
                        control={form.control}
                        name="specialCareApproved"
                        title="Special Care Approval"
                        content={
                            <>
                                <Typography paragraph>
                                    Materials of this type are quite sensitive and can shrink,
                                    distort, and/or develop marks, patterns, puckers, wrinkles or
                                    creases during processing. Also some napped fabrics can crush
                                    during treatment.
                                </Typography>
                                <Typography paragraph>
                                    We have successfully treated thousands of yards of velvets,
                                    chenilles, suede cloth and silk materials. These have been used
                                    in both commercial and residential properties. However, these
                                    types of fabrics can be unpredictable in their response. For
                                    instance, two velvets treated by the same process can respond in
                                    totally opposite ways. One may look great, the other may crush.
                                    Therefore we must disclaim financial responsibility for the cost
                                    of goods damaged or altered by the treatment process.
                                </Typography>
                                <Typography paragraph>
                                    Special care pricing will apply for these fabrics. Please ask
                                    for our price list if you do not have one.
                                </Typography>
                            </>
                        }
                        buttonLabel="I approve that I have read and acknowledged this notice"
                    />
                )}

                {fabric.plaidOrStriped && (
                    <Approval
                        control={form.control}
                        name="biasApproved"
                        title="Bias Approval"
                        content={
                            <>
                                <Typography paragraph>
                                    This fabric has a woven or printed pattern in the bias of the
                                    fabric (eg. plaid, horizontal stripe, or pattern). Fabrics of
                                    this type commonly have variations of 1/2 to one inch in the
                                    bias. Some variations have two inches or more.
                                </Typography>
                                <Typography paragraph>
                                    Because bias is so critical in some applications (such as wall
                                    upholstery), we must disclaim responsibility for pre- existing
                                    bias variations or those caused by instability of the fabric
                                    during processing.
                                </Typography>
                                <Typography paragraph>
                                    As we have no control over how these fabrics will respond during
                                    the process, we must disclaim financial responsibility for the
                                    cost of goods damaged or altered by the treatment process.
                                </Typography>
                                <Typography paragraph>
                                    As we have no control over how these fabrics will respond during
                                    the process, we must disclaim financial responsibility for the
                                    cost of goods damaged or altered by the treatment process.
                                </Typography>
                            </>
                        }
                        buttonLabel="I approve that I have read and acknowledged this notice"
                    />
                )}

                <div>
                    <LoadingButton
                        variant="contained"
                        loading={createApprovalDocumentMutation.isPending}
                        onClick={() => {
                            downloadApprovalPdf();
                        }}
                    >
                        Download Approval PDF
                    </LoadingButton>
                </div>

                {!isAuthenticated && (
                    <Approval
                        control={form.control}
                        name="tacAccepted"
                        title="Accept Terms & Conditions"
                        content={
                            <>
                                <Typography paragraph>
                                    Please read the{" "}
                                    <Link
                                        href="https://sbifinishing.com/terms-conditions/"
                                        target="_blank"
                                    >
                                        Terms &amp; Conditions
                                    </Link>
                                    .
                                </Typography>
                            </>
                        }
                        buttonLabel="I accept the Terms & Conditions"
                    />
                )}

                {!isAuthenticated && (
                    <div>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={import.meta.env.VITE_APP_RECAPTCHA_KEY}
                            onChange={(value) => {
                                form.setValue("captchaToken", value);
                            }}
                        />
                        {captchaFieldState.error && (
                            <FormHelperText error>{captchaFieldState.error.message}</FormHelperText>
                        )}
                    </div>
                )}
            </Stack>

            <OrderStepper {...stepperProps} />
        </Box>
    );
};

export default SummaryStep;
